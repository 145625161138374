import React from 'react';
import Main from "./component/Main";
import './App.css';


function App() {
    return (
        <Main />

    );
}

export default App;
