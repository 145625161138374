import React from 'react';
import Gallery from "../../Gallery";
import "./style.css";
import SnowStorm from 'react-snowstorm';

class GalleryPage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let snow;

        if (this.props.snow) {
            snow = <SnowStorm/>;
        }

        return (

            <div className="gall-qrcode-wraper App">
                {snow}
                <Gallery picture={this.props.picture}/>
            </div>

        )
    }
}

export default GalleryPage;