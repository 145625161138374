import React from 'react';
import Orderlist from "../../Orderlist";
import SnowStorm from 'react-snowstorm';


class Table1 extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let snow;

        if (this.props.snow) {
            snow = <SnowStorm/>;
        }

        return (
            <div className="App">
                {snow}
                <Orderlist maintable={1} picture={this.props.picture}/>
            </div>
        )
    }
}

export default Table1;