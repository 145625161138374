import React from 'react';
import Home from "../Pages/Home";
import Table from "../Pages/Table";
import Table1 from "../Pages/Table1";
import Table2 from "../Pages/Table2";
import Gallery from "../Pages/Gallerypage";

import {
    BrowserRouter,
    Switch,
    Route,
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = { snow: false, picture: true, video: false };
    }

    updateSnow = () => {
        this.setState({ snow: !this.state.snow });
    }

    updatePicture = () => {
        this.setState({ picture: !this.state.picture, video: !this.state.video });
    }

    updateVideo = () => {
        this.setState({ picture: !this.state.picture, video: !this.state.video });
    }



    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/table">
                        <Table snow={this.state.snow} picture={this.state.picture}/>
                    </Route>
                    <Route path="/table1">
                        <Table1 snow={this.state.snow} picture={this.state.picture}/>
                    </Route>
                    <Route path="/table2">
                        <Table2 snow={this.state.snow} picture={this.state.picture}/>
                    </Route>
                    <Route path="/gallery">
                        <Gallery snow={this.state.snow} picture={this.state.picture}/>
                    </Route>
                    <Route path="/">
                        <Home snow={this.state.snow}
                              video={this.state.video}
                              picture={this.state.picture}
                              updateSnow={this.updateSnow}
                              updatePicture={this.updatePicture}
                              updateVideo={this.updateVideo}
                        />
                    </Route>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default Main;
