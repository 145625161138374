import React from 'react';
import Orderlist from "../../Orderlist";
import SnowStorm from 'react-snowstorm';
import "./style.css";

class Table extends React.Component {

    constructor(props) {
        super(props);
    }

    render()
    {
        let snow;

        if (this.props.snow) {
            snow = <SnowStorm/>;
        }

        return (
            <div className="App">
                <div className="mobile-table">
                    {snow}
                    <Orderlist maintable={0} mobile={true} picture={this.props.picture}/>
                </div>
            </div>
        )
    }
}

export default Table;