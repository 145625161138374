import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import "./style.css";

class Gallery extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mobile: this.props.mobile,
            picture: this.props.picture
        };
    }

    render() {

        const {mobile} = this.state;

        const images = [
            {
                original: window.location.origin + '/slides/slide1.jpg',
            },
            {
                original: window.location.origin + '/slides/slide2.jpg',
            },
            {
                original: window.location.origin + '/slides/slide3.jpg',
            },
            {
                original: window.location.origin + '/slides/slide4.jpg',
            },
            {
                original: window.location.origin + '/slides/slide5.jpg',
            },
            {
                original: window.location.origin + '/slides/slide6.jpg',
            },
            {
                original: window.location.origin + '/slides/slide7.jpg',
            },
            {
                original: window.location.origin + '/slides/slide8.jpg',
            }
        ]

        if (mobile) {
            return <div></div>;
        } else {
            if (this.props.picture) {
                return (<ImageGallery items={images}
                                      showNav={false}
                                      autoPlay={true}
                                      useBrowserFullscreen={true}
                                      showPlayButton={false}
                                      showFullscreenButton={false}
                                      showThumbnails={false}
                                      slideDuration={4400}
                                      slideInterval={24000}
                />)
            } else {
                return (<div>
                    <video width="100%" height="auto" autoPlay="autoplay" loop="loop" preload="auto" muted>
                        <source src="/video/pinch-showroom_5.mp4" type="video/mp4"></source>
                    </video>
                </div>)
            }
        }
    }
}

export default Gallery;